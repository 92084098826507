<template>
  <div class="manage-property animatedBox">
    <div class="container fluid">
      <!-- ===================== Filter ===================== -->
      <filter-panel
        v-model="isFilterActive"
        @clear="clearFilter"
        @filter="filterData"
      >
        <div class="row p-2">
          <fd-select
            v-model="filter.status"
            class="col-12 sm-col-6 md-col-3 px-1 mb-2"
            :options="statusOptions"
            label="Status"
            name="status"
          ></fd-select>
          <fd-input
            v-model="filter[`referenceCode[partial]`]"
            class="col-12 sm-col-6 md-col-3 px-1 mb-2"
            label="Reference Code"
            name="referenceCode"
            type="text"
            @keyup.native.enter="filterData()"
          ></fd-input>
          <fd-input
            v-model="filter[`ownerPropertyCode:code`]"
            class="col-12 sm-col-6 md-col-3 px-1 mb-2"
            label="Owner Property Code"
            name="filterOwnerPropertyCode"
            type="text"
            @keyup.native.enter="filterData()"
          ></fd-input>
          <fd-input
            v-model="filter['title[partial]']"
            class="col-12 sm-col-6 md-col-3 px-1 mb-2"
            label="Title"
            name="title"
            type="text"
            @keyup.native.enter="filterData()"
          ></fd-input>
          <fd-select
            v-model="filter.purpose"
            class="col-12 sm-col-6 md-col-3 px-1 mb-2"
            :options="purposeOptions"
            label="Purpose"
            name="purpose"
            type="text"
          ></fd-select>
          <fd-input
            v-model="filter['lotNumber[partial]']"
            class="col-12 sm-col-6 md-col-3 px-1 mb-2"
            label="Lot No."
            name="lotNumber"
            type="text"
            @keyup.native.enter="filterData()"
          ></fd-input>
          <fd-input
            v-model="filter['street[partial]']"
            class="col-12 sm-col-6 md-col-3 px-1 mb-2"
            label="Street"
            name="street"
            type="text"
            @keyup.native.enter="filterData()"
          ></fd-input>
          <fd-input
            v-model="filter['area:name[partial]']"
            class="col-12 sm-col-6 md-col-3 px-1 mb-2"
            label="Area"
            name="area"
            type="text"
            @keyup.native.enter="filterData()"
          ></fd-input>
        </div>
      </filter-panel>

      <!-- =================== Table Listing =================== -->
      <customized-tab
        :currentTab="currentTab"
        :tabs="tabs.slice(0, canViewAgencyProperty ? 2 : 1)"
        @tabChange="onTabChange"
      >
        <tab-window v-if="currentTab == 0">
          <!-- Table -->
          <vue-good-table
            mode="remote"
            @on-page-change="onPageChange"
            @on-per-page-change="onPerPageChange"
            :columns="propertyTableColumns"
            :rows="propertyTableData"
            :totalRows="propertyTablePagination.total"
            :pagination-options="{
              enabled: true,
              mode: 'pages',
              perPage: 30,
              perPageDropdown: perPageOptions,
              dropdownAllowAll: false
            }"
            :sort-options="{
              enabled: false
            }"
            @on-row-click="(e) => toPropertyDetail(e.row.id)"
          >
            <div slot="emptystate">
              <no-data message="There is no data"></no-data>
            </div>
            <!-- Table actions -->
            <div slot="table-actions" class="p-2">
              <button class="btn main mr-2" @click="toAddProperty">
                <i class="fas fa-plus"></i>
              </button>
              <button
                :class="{ active: isFilterActive }"
                class="btn toggle"
                @click="filterToggle"
              >
                <i class="fas fa-filter"></i>
              </button>
            </div>
            <!-- Table Buttons -->
            <template slot="table-row" slot-scope="props">
              <span
                v-if="
                  props.column.field == 'actions' &&
                  (canDeleteMyProperty(props.row.status) ||
                    canUpdateMyProperty(props.row.status))
                "
                @click.stop
              >
                <dropdown-button buttonClass="circle flat">
                  <template #buttonContent>
                    <i class="fas fa-ellipsis-v"></i>
                  </template>
                  <template>
                    <div class="card text-left">
                      <!-- Repost -->
                      <fd-list-item
                        v-if="canUpdateMyProperty(props.row.status)"
                        class="cursor-pointer unselectable"
                        @click="repostProperty(props.row.id, 'self')"
                      >
                        <i class="fas fa-refresh"></i>
                        <span class="ml-2">Repost</span>
                      </fd-list-item>
                      <!-- Archive As Rent -->
                      <fd-list-item
                        v-if="
                          canArchiveForRent(props.row.purpose, props.row.status)
                        "
                        @click="openArchiveRentModal(props.row)"
                      >
                        <i class="fas fa-archive"></i>
                        <span class="ml-2">Archive As Rent</span>
                      </fd-list-item>
                      <hr />

                      <!-- Update -->
                      <fd-list-item
                        v-if="canUpdateMyProperty(props.row.status)"
                        class="cursor-pointer unselectable"
                        @click="toEditProperty(props.row.id, 'self')"
                      >
                        <i class="fas fa-pen"></i>
                        <span class="ml-2">Edit Property</span>
                      </fd-list-item>
                      <!-- Exclusive -->
                      <fd-list-item
                        v-if="
                          canUpdateMyProperty(props.row.status) &&
                          props.row.purpose !== PURPOSE.AUCTION
                        "
                        class="cursor-pointer unselectable"
                        @click="toManageExclusive(props.row.id)"
                      >
                        <i class="fas fa-star"></i>
                        <span class="ml-2">Update Exclusive</span>
                      </fd-list-item>
                      <!-- Change Price -->
                      <fd-list-item
                        v-if="canUpdateMyProperty(props.row.status)"
                        class="cursor-pointer unselectable"
                        @click="openChangePrice(props.row)"
                      >
                        <i class="fas fa-tags"></i>
                        <span class="ml-2">Change Price</span>
                      </fd-list-item>
                      <!-- Change Status -->
                      <fd-list-item
                        v-if="canUpdateMyProperty(props.row.status)"
                        class="cursor-pointer unselectable"
                        @click="openChangeStatus(props.row)"
                      >
                        <i class="fas fa-exchange-alt"></i>
                        <span class="ml-2">Change Status</span>
                      </fd-list-item>
                      <hr />

                      <!-- Delete -->
                      <fd-list-item
                        v-if="canDeleteMyProperty(props.row.status)"
                        @click="deleteProperty(props.row)"
                      >
                        <i class="fas fa-trash"></i>
                        <span class="ml-2">Delete Property</span>
                      </fd-list-item>
                    </div>
                  </template>
                </dropdown-button>
              </span>
              <span v-else-if="props.column.field == 'status'">
                <property-status :status="props.row.status"></property-status>
              </span>
              <span v-else-if="props.column.field == 'title'">
                <exclusive-badge v-if="props.row.isExclusive"></exclusive-badge>
                {{ props.row.title }}
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
          </vue-good-table>
        </tab-window>

        <!-- Agency's Property -->
        <tab-window v-if="currentTab == 1 && canViewAgencyProperty">
          <!-- Table -->
          <vue-good-table
            mode="remote"
            @on-page-change="onPageChange"
            @on-per-page-change="onPerPageChange"
            :columns="propertyTableColumns"
            :rows="propertyTableData"
            :totalRows="propertyTablePagination.total"
            :pagination-options="{
              enabled: true,
              mode: 'pages',
              perPage: 30,
              perPageDropdown: perPageOptions,
              dropdownAllowAll: false
            }"
            :sort-options="{
              enabled: false
            }"
            @on-row-click="(e) => toPropertyDetail(e.row.id)"
          >
            <div slot="emptystate">
              <no-data message="There is no data"></no-data>
            </div>
            <!-- Table actions -->
            <div slot="table-actions" class="p-2">
              <button
                :class="{ active: isFilterActive }"
                class="btn toggle"
                @click="filterToggle"
              >
                <i class="fas fa-filter"></i>
              </button>
            </div>
            <!-- Table Buttons -->
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field == 'actions'" @click.stop>
                <dropdown-button v-if="canEdit" buttonClass="circle flat">
                  <template #buttonContent>
                    <i class="fas fa-ellipsis-v"></i>
                  </template>
                  <template>
                    <div class="card text-left">
                      <!-- Change Status -->
                      <fd-list-item
                        class="cursor-pointer unselectable"
                        @click="openChangeStatus(props.row)"
                      >
                        <i class="fas fa-exchange-alt"></i>
                        <span class="ml-2">Change Status</span>
                      </fd-list-item>
                      <!-- Delete -->
                      <!-- <fd-list-item @click="deleteProperty(props.row)">
                        <i class="fas fa-trash"></i>
                        <span class="ml-2">Delete Property</span>
                      </fd-list-item> -->
                    </div>
                  </template>
                </dropdown-button>
              </span>
              <span v-else-if="props.column.field == 'status'">
                <property-status :status="props.row.status"></property-status>
              </span>
              <span v-else-if="props.column.field == 'title'">
                <exclusive-badge v-if="props.row.isExclusive"></exclusive-badge>
                {{ props.row.title }}
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
          </vue-good-table>
        </tab-window>
      </customized-tab>
    </div>
    <!-- ===================== Modal for Change Status ===================== -->
    <modal v-model="changeStatus.isModalShown" persistent>
      <change-status
        :property="changePrice.property"
        @close="changeStatus.isModalShown = false"
        @update="updateStatus"
      ></change-status>
    </modal>
    <!-- ===================== Modal for Change Price ====================== -->
    <modal v-model="changePrice.isModalShown" persistent>
      <change-price
        :property="changePrice.property"
        @close="changePrice.isModalShown = false"
        @update="updatePrice"
      ></change-price>
    </modal>
    <!-- ===================== Modal for Archive Rent ====================== -->
    <modal v-model="archiveRent.isModalShown" persistent fullscreen>
      <archive-rent-form
        class="card sharp"
        :property="archiveRent.property"
        @close="archiveRent.isModalShown = false"
      >
      </archive-rent-form>
    </modal>
  </div>
</template>

<script>
import vueGoodTable from "@/mixins/vue-good-table/mixin";
// Enums
import propertyCategoryEnum from "@/enums/propertyCategory";
import { purpose, propertyStatus } from "@/enums";
import furnishingTypeEnum from "@/enums/furnishingType";

export default {
  name: "manage-property",
  mixins: [vueGoodTable],
  components: {
    DropdownButton: () =>
      import("@/components/GlobalComponents/DropdownButton"),
    FdListItem: () => import("@/components/GlobalComponents/List/FdListItem"),
    ChangeStatus: () => import("@/modules/Property/components/ChangeStatus"),
    ChangePrice: () => import("@/modules/Property/components/ChangePrice"),
    ArchiveRentForm: () =>
      import("@/modules/Rent/components/ArchiveRent/ArchiveRentForm"),
    PropertyStatus: () =>
      import("@/components/GlobalComponents/Shared/Property/PropertyStatus"),
    ExclusiveBadge: () =>
      import("@/components/GlobalComponents/Shared/Property/ExclusiveBadge")
  },
  data() {
    return {
      currentTab: 0,
      tabs: ["My Property", "Agency's Property"],
      PURPOSE: purpose,
      STATUS: propertyStatus,

      canViewAgencyProperty: false,
      canAdd: false,
      canEdit: false,
      canDelete: false,
      propertyTableColumns: [
        {
          label: "",
          field: "actions",
          tdClass: "text-center fixed-column",
          sortable: false
        },
        {
          label: "Status",
          field: "status",
          tdClass: "text-center fixed-column"
        },
        {
          label: "Reference Code",
          field: "referenceCode"
        },
        {
          label: "Title",
          field: "title",
          width: "250px"
        },
        {
          label: "Purpose",
          field: "purpose"
        },
        {
          label: "Lot No.",
          field: "lotNumber"
        },
        {
          label: "Street",
          field: "street",
          width: "180px"
        },
        {
          label: "Area",
          field: "area",
          formatFn: (val) => `${val.name} ${val.postalCode}`
        },

        {
          label: "Sale Price",
          field: "salePrice",
          formatFn: (value) => {
            return this.$convertNullIfEmpty(value)
              ? `RM ${this.$numberWithCommas(parseInt(value))}`
              : "-";
          },
          width: "100px"
        },
        {
          label: "Rent Price",
          field: "rentPrice",
          formatFn: (value) => {
            return this.$convertNullIfEmpty(value)
              ? `RM ${this.$numberWithCommas(parseInt(value))}`
              : "-";
          },
          width: "100px"
        },
        {
          label: "Created",
          field: "createdAt",
          formatFn: (value) => this.$moment(value).format("DD-MM-YYYY, HH:mm A")
        },
        {
          label: "Last Updated",
          field: "updatedAt",
          formatFn: (value) => this.$moment(value).format("DD-MM-YYYY, HH:mm A")
        }
      ],
      tableParams: {
        page: 1,
        perPage: 30
      },
      propertyTableData: [],
      propertyTablePagination: {},
      // Filter Related
      isFilterActive: false,
      filter: {
        "referenceCode[partial]": "",
        "ownerPropertyCode:code": "",
        "lotNumber[partial]": "",
        "street[partial]": "",
        "area:name[partial]": "",
        purpose: ""
      },
      categoryOptions: this.$mapJsonToArray(propertyCategoryEnum, (e) => {
        return {
          id: e,
          name: e
        };
      }),
      furnishingTypeOptions: this.$mapJsonToArray(furnishingTypeEnum, (e) => {
        return {
          id: e,
          name: e
        };
      }),
      purposeOptions: this.$mapJsonToArray(purpose, (e) => {
        return {
          id: e,
          name: e
        };
      }),
      statusOptions: this.$mapJsonToArray(propertyStatus, (e) => {
        return {
          id: e,
          name: e
        };
      }),

      // Change status
      changeStatus: {
        property: {},
        isModalShown: false
      },
      // Change price
      changePrice: {
        property: {},
        isModalShown: false
      },
      // Archive Rent
      archiveRent: {
        property: {},
        isModalShown: false
      }
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    onTabChange(index) {
      this.currentTab = index;

      this.getAllProperties();
    },
    async init() {
      await Promise.all([this.getAccessPermission(), this.getAllProperties()]);
    },
    async getAccessPermission() {
      this.canViewAgencyProperty = await this.$store.dispatch(
        "manageAccount/checkPermission",
        "property.view"
      );
      this.canAdd = await this.$store.dispatch(
        "manageAccount/checkPermission",
        "property.create"
      );
      this.canEdit = await this.$store.dispatch(
        "manageAccount/checkPermission",
        "property.update"
      );
      this.canDelete = await this.$store.dispatch(
        "manageAccount/checkPermission",
        "property.delete"
      );
    },
    toPropertyDetail(id) {
      this.$router.push({
        name: "ManagePropertyDetail",
        params: {
          id: id,
          type: this.currentTab == 1 ? "agency" : "my"
        }
      });
    },
    // ===================== Table related methods =====================
    updateParams(newProps) {
      this.tableParams = Object.assign({}, this.tableParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.getAllProperties();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.getAllProperties();
    },
    filterToggle() {
      this.isFilterActive = !this.isFilterActive;
    },
    toAddProperty() {
      this.$router.push({ name: "ManagePropertyAdd" });
    },
    toEditProperty(id, role) {
      this.$router.push({
        name: "ManagePropertyEdit",
        params: { id: id },
        query: { role: role }
      });
    },
    toManageExclusive(id) {
      this.$router.push({
        name: "ManagePropertyExclusive",
        params: { id: id }
      });
    },
    openArchiveRentModal(property) {
      this.archiveRent.property = property;
      this.archiveRent.isModalShown = true;
    },
    openChangeStatus(property) {
      this.changePrice.property = property;
      this.changeStatus.isModalShown = true;
    },
    openChangePrice(property) {
      this.changePrice.property = property;
      this.changePrice.isModalShown = true;
    },
    canArchiveForRent(purpose, status) {
      let isRent =
        purpose === this.PURPOSE.SALE_AND_RENT || purpose === this.PURPOSE.RENT;
      let isNotClosed =
        status === this.STATUS.ACTIVE || status === this.STATUS.INACTIVE;
      if (isRent && isNotClosed) {
        return true;
      }
      return false;
    },
    canUpdateMyProperty(status) {
      if (
        status == propertyStatus.DEALT_BY_OTHER ||
        status == propertyStatus.SOLD
      ) {
        return false;
      }
      return true;
    },
    canDeleteMyProperty(status) {
      if (
        status == propertyStatus.DEALT_BY_OTHER ||
        status == propertyStatus.SOLD ||
        status == propertyStatus.RENT_OUT
      ) {
        return false;
      }
      return true;
    },
    // ===================== Filter related methods =====================
    filterData() {
      this.tableParams.page = 1;
      this.getAllProperties();
    },
    clearFilter() {
      if (this.$isFilterEmpty(this.filter)) {
        return;
      } else {
        this.tableParams.page = 1;
        this.filter = this._.mapValues(this.filter, () => null);
        this.getAllProperties();
      }
    },

    // ============================= API Related ===============================
    async getAllProperties() {
      this.$store.commit("setIsLoading", true);
      try {
        let filteredParam = this.$cleanQueryParam(this.filter);

        let data = await this.$store.dispatch(
          this.currentTab == 0
            ? "manageProperty/getMyProperties"
            : "manageProperty/getAgencyProperties",
          {
            queries: filteredParam,
            page: this.tableParams.page,
            perPage: this.tableParams.perPage
          }
        );

        this.propertyTableData = this._.cloneDeep(data.data);
        this.propertyTablePagination = this._.cloneDeep(data.meta.pagination);
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        throw error;
      }
    },
    async deleteProperty(property) {
      let id = property.id;
      let refCode = property.referenceCode;
      let name = property.title;

      let message = `Are you sure you want to delete ${name} (${refCode})?`;
      let confirmDelete = await this.$confirm({
        message: message,
        type: "alert"
      });
      if (confirmDelete) {
        this.confirmDeleteProperty(id);
      }
    },
    async confirmDeleteProperty(id) {
      try {
        this.$store.commit("setIsLoading", true);
        await this.$store.dispatch("manageProperty/deleteMyProperty", {
          id: id
        });
        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "Property has been deleted successfully."
        });
        await this.getAllProperties();
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "An unexpected error occured. Please try again later."
        });
        this.$reportError(error, "Delete My Property");
        throw error;
      }
    },
    async updateStatus({ id, status }) {
      this.$store.commit("setIsLoading", true);
      try {
        this.changeStatus.isModalShown = false;
        await this.$store.dispatch(
          this.currentTab == 0
            ? "manageProperty/updateMyStatus"
            : "manageProperty/updateStatus",
          {
            id: id,
            status: status
          }
        );
        this.$store.commit("setIsLoading", false);
        await this.getAllProperties();
        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "Status has been updated successfully."
        });
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        throw error;
      }
    },
    async updatePrice({ id, priceData }) {
      this.$store.commit("setIsLoading", true);
      try {
        this.changePrice.isModalShown = false;

        await this.$store.dispatch(
          this.currentTab == 0
            ? "manageProperty/updateMyPrice"
            : "manageProperty/updatePrice",
          {
            id: id,
            payload: priceData
          }
        );
        this.$store.commit("setIsLoading", false);
        await this.getAllProperties();
        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "Price data has been updated successfully."
        });
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to update property price. Please try again later."
        });
        throw error;
      }
    },
    async repostProperty(id) {
      try {
        this.$store.commit("setIsLoading", true);
        await this.$store.dispatch("manageProperty/repostProperty", id);
        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "Listing has been updated."
        });
        this.$store.commit("setIsLoading", false);
        await this.getAllProperties();
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to repost listing. Please try again later."
        });
      }
    },
    async archivePropertyAsRent() {
      try {
        // TODO: call archive rent api
      } catch (error) {
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text:
            "Failed to archive this property as rent. Please try again later."
        });
      }
    }
  }
};
</script>

<style lang="scss">
.manage-property {
  .btn.flat {
    &.toggle {
      background: none;
      color: #9b9b9b;
      &:hover {
        background: #e6e6e6;
      }
      &.active {
        background: $color-main;
        color: white;
        box-shadow: 0px 4px 10px #00000025;
        &:hover {
          box-shadow: none;
        }
      }
    }
  }
}
</style>
